<template>
    <video @success="$emit('success')" v-bind="$attrs" type="application/x-mpegURL"></video>
</template>
<script>
import HLS from "hls.js";

export default {
    props: {
        srcId: {
            type: String,
            required: true,
        },
    },

    data: () => {
        return {
            playingVideo: false,
            mutedVideo: false,
            finishedVideo: false,
        };
    },

    methods: {
        play() {
            this.finishedVideo = false;
            this.playingVideo = true;
            this.$el
                .play()
                .then()
                .catch((error) => {
                    console.log(error);
                    this.pause();
                });
        },
        pause() {
            this.$el.pause();
            this.playingVideo = false;
        },
        reset() {
            this.finishedMovie = false;
            this.$el.currentTime = 0;
        },
        mute() {
            this.$el.muted = true;
            this.mutedVideo = true;
        },
        unmute() {
            this.$el.muted = false;
            this.mutedVideo = false;
        },
    },

    watch: {
        mutedVideo(bool) {
            this.$emit("muted", bool);
        },
        finishedVideo(bool) {
            this.$emit("finished", bool);
        },
        playingVideo(bool) {
            this.$emit("playing", bool);
        },
    },

    mounted() {
        const video = this.$el;
        const videoSrc = `https://stream.mux.com/${this.srcId}.m3u8`;
        if (HLS.isSupported()) {
            const hls = new HLS();
            hls.loadSource(videoSrc);
            hls.attachMedia(video);
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = videoSrc;
        }

        this.$el.addEventListener("ended", () => {
            this.finishedVideo = true;
            this.playingVideo = false;
        });
    },
};
</script>
