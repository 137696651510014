<template>
    <div class="dynamic-container careers-office-image" ref="officeParallax">
        <div class="careers-office-image__wrapper">
            <div class="careers-office-image__slider" :style="{ width: `${100 + MAX_OFFSET + 5}%`, transform: `translateX(-${scrollOffset}%)` }">
                <picture>
                    <source
                        loading="lazy"
                        media="(min-width: 823px)"
                        width="2702"
                        height="1108"
                        :srcset="$asset('images/careers/office.jpg')"
                        alt="Inside of Procreate HQ. This modern office has meny open plan spaces, vegetation and rustic tones."
                    />
                    <img
                        loading="lazy"
                        :src="$asset('images/careers/office-sm.jpg')"
                        width="1621"
                        height="927"
                        alt="Inside of Procreate HQ. This modern office has meny open plan spaces, vegetation and rustic tones."
                    />
                </picture>
            </div>
        </div>
    </div>
</template>
<script>
const MAX_OFFSET = 10;

export default {
    data: () => {
        return {
            MAX_OFFSET,
            scrollOffset: MAX_OFFSET,
        };
    },

    methods: {
        easeInOutSine(t) {
            return -0.5 * (Math.cos(Math.PI * t) - 1);
        },

        setScrollOffset() {
            const el = this.$refs.officeParallax;
            if (el) {
                const height = el.offsetHeight;
                const top = el.offsetTop - (window.innerHeight - height);
                const halfway = top + height + window.innerHeight;
                const point = window.scrollY + window.innerHeight / 2;

                const range = halfway - top;

                if (point > top && point < halfway) {
                    const innerScrollPoint = point - top;
                    this.scrollOffset = (this.easeInOutSine(innerScrollPoint / range) * 100) / (100 / MAX_OFFSET);
                } else if (point <= top) {
                    this.scrollOffset = 0;
                } else if (point >= halfway) {
                    this.scrollOffset = MAX_OFFSET;
                }
            }
        },

        scrollEvents() {
            this.setScrollOffset();
        },
    },

    mounted() {
        // Work Scroll Offset
        window.addEventListener("scroll", this.scrollEvents);
        new ResizeObserver(() => {
            this.setScrollOffset();
        }).observe(this.$refs.officeParallax);
        this.setScrollOffset();
    },
};
</script>
