<template>
    <div v-if="positions.length > 0" class="careers-positions__jobs-wrapper">
        <ul ref="list">
            <span class="careers-positions__jobs">
                <li class="careers-position" v-for="position in initialPositions" :key="position.slug">
                    <h3 class="text-heading text-heading--xs">{{ position.title }}</h3>
                    <p class="text-body">{{ position.description }}</p>
                    <ProLink :to="{ name: 'careers-position', params: { position: position.slug } }" :aria-label="position.title"></ProLink>
                </li>
            </span>
            <TransitionHeight>
                <span v-show="showAllPositions" class="careers-positions__jobs careers-positions__jobs--additional">
                    <li class="careers-position" v-for="position in revealedPositions" :key="position.slug">
                        <h3 class="text-heading text-heading--xs">{{ position.title }}</h3>
                        <p class="text-body">{{ position.description }}</p>
                        <ProLink :to="{ name: 'careers-position', params: { position: position.slug } }" :aria-label="position.title"></ProLink>
                    </li>
                </span>
            </TransitionHeight>
        </ul>
        <div>
            <ProButton v-if="revealedPositions.length != 0" @click="handleShowPositions()" pill class="new-button"
                >{{ showAllPositions ? $t("careers.jobs.button_less") : $t("careers.jobs.button_more")
                }}<ProIcon icon="arrow-s" :style="{ width: '1.2em', transform: showAllPositions ? 'scaleY(-1)' : null }"
            /></ProButton>
        </div>
    </div>
</template>
<script>
import TransitionHeight from "@/components/TransitionHeight.vue";

export default {
    components: { TransitionHeight },
    props: {
        positions: {
            type: Array,
            required: true,
        },
    },

    data: () => {
        return {
            initialPositionCount: 4,
            showAllPositions: false,
        };
    },

    methods: {
        handleShowPositions() {
            if (this.showAllPositions) this.$emit("collapse");
            this.showAllPositions = !this.showAllPositions;
            if (this.showAllPositions)
                setTimeout(() => {
                    this.$refs.list.lastChild.firstChild.querySelector("a").focus();
                }, 500);
        },
    },

    computed: {
        initialPositions() {
            return this.positions.slice(0, this.initialPositionCount);
        },

        revealedPositions() {
            return this.positions.slice(this.initialPositionCount);
        },
    },
};
</script>
