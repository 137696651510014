<template>
    <Page>
        <div class="careers-spacing">
            <section ref="banner" class="careers-banner">
                <div class="careers-hero" :class="{ 'careers-hero--active': heroActive, 'careers-hero--transitioning': heroTransitioning }">
                    <div class="dynamic-container">
                        <div class="careers-hero__copy">
                            <h1 class="text-subheading careers-subheading">{{ $t("careers.banner.leader") }}</h1>
                            <p class="text-heading careers-hero__heading" v-html="$t('careers.banner.heading')"></p>
                        </div>
                    </div>
                    <div
                        id="careers-banner-theater"
                        ref="heroVidWrapper"
                        class="dynamic-container careers-hero__media"
                        :aria-roledescription="$t('careers.banner.theater_role')"
                        :aria-label="$t('careers.banner.theater_label')"
                    >
                        <div class="careers-hero__media-wrapper">
                            <picture aria-hidden="true">
                                <source media="(min-width: 600px)" :srcset="$asset('images/careers/banner-image.jpg')" height="1152" width="2048" alt="" />
                                <img @click="handleHeroActive" :src="$asset('images/careers/banner-image_sm.jpg')" height="1087" width="1450" alt="" />
                            </picture>
                            <ProButton
                                aira-controls="careers-banner-theater"
                                v-show="!heroActive"
                                @click="handleHeroActive"
                                class="new-button"
                                pill
                                variant="secondary"
                                ><ProIcon icon="play-round" :style="{ width: '1.2em' }" />{{ $t("careers.banner.button_label") }}</ProButton
                            >
                            <HlsVideo
                                srcId="hVnr8VkixrUvpDWal2i1mIudQVKF6Wh02dNUcNdDLm7I"
                                ref="mobileHeroVid"
                                class="careers-fullscreen-vid"
                                disableremoteplayback
                                x-webkit-airplay="deny"
                                contenteditable="false"
                                :aria-hidden="mobileHeroActive"
                            />
                            <!-- <video
                                src="https://s3.us-west-2.amazonaws.com/assets.procreate.art/careers/careers-video.mp4?v=1"
                            ></video> -->
                            <HeroPlayer v-if="heroActive" />
                        </div>
                    </div>
                </div>

                <!-- Rare Breed -->
                <div class="dynamic-container">
                    <div class="careers-join-us">
                        <h2 class="text-heading" v-html="$t('careers.join.heading')"></h2>
                        <div class="careers-join-us__copy">
                            <AvatarDisplay :avatars="employeeAvatars" :total="flooredEmployeeCount" />
                            <p class="text-heading text-heading--xs procreate-copy__dimmable" v-html="$t('careers.join.body')"></p>
                            <div>
                                <ProButton @click="goToPositions()" pill class="new-button" variant="primary">{{ $t("careers.join.button_label") }}</ProButton>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Future of Creativity -->
            <section>
                <div class="dynamic-container careers-future">
                    <div class="careers-future__image">
                        <img
                            loading="lazy"
                            :src="$asset('images/careers/future.jpg')"
                            width="1436"
                            height="1108"
                            alt="Artist testing Procreate's 3D painting features."
                        />
                    </div>
                    <div class="careers-future__copy">
                        <h2 class="text-heading text-heading--md" v-html="$t('careers.future.heading')"></h2>
                        <div>
                            <p class="text-body procreate-copy__dimmable" v-html="$t('careers.future.body')"></p>
                            <div>
                                <ProButton :to="{ name: 'who-we-are' }" pill class="new-button">{{ $t("careers.future.button_label") }}</ProButton>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Beautiful Designs -->
            <section>
                <div class="dynamic-container">
                    <div class="procreate-copy procreate-copy--center">
                        <h2 class="text-heading text-heading--md" v-html="$t('careers.design.heading')"></h2>
                        <div class="careers-awards">
                            <div v-for="(award, index) in $t('careers.awards')" :key="index" class="laurel-badge">
                                <img :src="$asset(award.badge)" :alt="award.title" height="51" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dynamic-container careers-design">
                    <div class="careers-design__image">
                        <picture>
                            <source
                                loading="lazy"
                                media="(min-width: 823px)"
                                width="2612"
                                height="1050"
                                :srcset="$asset('images/careers/design_desktop.webp')"
                                alt="Close-up view of UI elements for Procreate and Procreate Dreams"
                            />
                            <img
                                loading="lazy"
                                :src="$asset('images/careers/design_mobile.webp')"
                                width="740"
                                height="1160"
                                alt="Close-up view of UI elements for Procreate and Procreate Dreams"
                            />
                        </picture>
                    </div>
                </div>

                <div class="dynamic-container">
                    <div class="procreate-copy">
                        <p class="text-heading text-heading--xs procreate-copy__dimmable" v-html="$t('careers.design.body')"></p>
                    </div>
                </div>
            </section>

            <!-- Freedom to Invent -->
            <section class="careers-freedom">
                <div class="dynamic-container">
                    <div class="procreate-copy">
                        <h2 class="text-heading text-heading--md" v-html="$t('careers.freedom.heading')"></h2>
                    </div>
                    <div class="careers-freedom-tiles">
                        <div class="careers-freedom-tile careers-freedom-tile--image">
                            <img :src="$asset('images/careers/creatives.jpg')" alt="" />
                            <div class="careers-freedom-tile__copy">
                                <h3 class="text-heading text-heading--sm" v-html="this.$t('careers.office.heading', { employees: employeeCount })"></h3>
                                <p class="text-body" v-html="this.$t('careers.office.body')"></p>
                            </div>
                        </div>
                        <div class="careers-freedom-tiles-grid">
                            <div v-for="(tile, i) in freedomTiles" :key="i" :class="`careers-freedom-tile careers-freedom-tile--${tile.bg_type} `">
                                <div class="careers-freedom-tile__copy">
                                    <h3 class="text-heading text-heading--sm" v-html="tile.heading"></h3>
                                    <p v-if="tile.body" class="text-body" v-html="tile.body"></p>
                                    <button @click="goToPositions()" v-if="tile.buttonLabel" type="button" :aria-label="tile.buttonLabel">
                                        <span><ProIcon icon="arrow-s" /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Talent Carousel -->
            <section style="overflow: hidden">
                <div class="dynamic-container">
                    <div class="procreate-copy procreate-copy--center procreate-copy--narrow">
                        <h2 class="text-heading text-heading--md" v-html="$t('careers.talent.heading')"></h2>
                    </div>
                </div>
                <TalentCarousel />
                <div class="dynamic-container">
                    <div class="procreate-copy procreate-copy--center procreate-copy--narrow">
                        <p class="text-body" v-html="$t('careers.talent.body')"></p>
                    </div>
                </div>
            </section>

            <!-- The actual jobs -->
            <section id="jobs">
                <OfficeParallax />
                <div ref="positions" class="dynamic-container" :style="{ marginTop: '-150px', paddingTop: '150px' }">
                    <h2 class="careers-positions__heading text-heading text-heading--md" v-html="$t('careers.jobs.heading')"></h2>
                    <div class="careers-positions">
                        <div v-if="positions.length > 0" class="careers-positions__copy">
                            <p class="text-body" v-html="$t('careers.jobs.body')"></p>
                        </div>
                        <div v-else class="careers-positions__copy">
                            <p class="text-body" v-html="$t('careers.jobs.no_positions_body')"></p>
                            <div>
                                <ProButton class="new-button" href="https://www.linkedin.com/company/procreate-art/" target="_blank"
                                    >{{ $t("careers.jobs.linkedin_prompt") }}<ProIcon :style="{ width: '0.95em' }" icon="arrow-ne"
                                /></ProButton>
                            </div>
                        </div>
                        <PositionsList :positions="positions" @collapse="goToPositions" />
                    </div>
                </div>
            </section>

            <!-- All about Tassie -->
            <section>
                <div class="careers-tassie__mobile-slider">
                    <TassieSliderMobile />
                </div>
                <div class="dynamic-container">
                    <div class="careers-tassie">
                        <div class="careers-tassie__slides">
                            <TassieSlider />
                        </div>
                        <div class="careers-tassie__copy">
                            <div>
                                <h2 class="text-subheading careers-subheading">{{ $t("careers.tassie.leader") }}</h2>
                                <p class="text-heading text-heading--md" v-html="$t('careers.tassie.heading')"></p>
                            </div>
                            <div class="text-body careers-tassie__body procreate-copy__dimmable" v-html="$t('careers.tassie.body')"></div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- FAQ -->
            <section id="faq">
                <div class="dynamic-container">
                    <div class="careers-faq">
                        <div class="procreate-copy">
                            <h2 class="text-heading" v-html="$t('careers.faq.heading')"></h2>
                        </div>
                        <AccordionSet ref="faqAccordions">
                            <Accordion
                                v-for="(faq, key) in $t('careers.faq.items')"
                                role="listitem"
                                :key="key"
                                :heading="faq.heading"
                                @update="handleFaqUpdate"
                            >
                                <div class="careers-faq__question-body text-body" v-html="faq.body"></div>
                            </Accordion>
                        </AccordionSet>
                    </div>
                </div>
            </section>

            <!-- In the News -->
            <section class="careers-news">
                <div class="dynamic-container">
                    <div class="procreate-copy">
                        <h2 class="text-heading" v-html="$t('careers.news.heading')"></h2>
                    </div>
                    <div class="careers-news__items">
                        <ProLink
                            v-for="(article, i) in inTheNews"
                            :aria-label="article.title"
                            :href="`${article.url}?utm_source=procreate&utm_medium=calltoaction&utm_campaign=careers`"
                            target="_blank"
                            :key="i"
                        >
                            <div class="careers-news-item" aria-hidden="true">
                                <div class="careers-news-item__image">
                                    <img :src="$asset(article.image)" alt="" loading="lazy" />
                                </div>
                                <div class="careers-news-item__copy">
                                    <h3 class="text-heading text-heading--xs" v-html="article.title"></h3>
                                    <div class="careers-news-item__meta">
                                        <img :src="$asset(article.icon)" width="16" height="16" />
                                        <span>{{ article.publication }}</span>
                                        <ProIcon icon="offlink-arrow" />
                                    </div>
                                </div>
                            </div>
                        </ProLink>
                    </div>
                </div>
            </section>
        </div>
        <StickyBanner v-if="$refs.banner" :triggerEl="$refs.banner" containerType="dynamic-container" class="careers-cta-sticky">
            <p class="text-heading" v-html="$t('careers.cta.heading')"></p>
            <ProButton @click="goToPositions()" pill class="new-button new-button--square" variant="primary">{{ $t("careers.cta.button_label") }}</ProButton>
        </StickyBanner>
    </Page>
</template>

<script>
import { metaTitles, metaDescriptions, metaImages } from "~/utils/meta";
import { trimI18nHeadLinks, constructi18nHeadMeta } from "~/utils/i18n";
import { mapGetters } from "vuex";

import TransitionHeight from "@/components/TransitionHeight.vue";
import HlsVideo from "@/components/HlsVideo.vue";

import TalentCarousel from "@/components/careers/TalentCarousel.vue";
import TassieSlider from "@/components/careers/TassieSlider.vue";
import TassieSliderMobile from "@/components/careers/TassieSliderMobile.vue";
import AvatarDisplay from "@/components/careers/AvatarDisplay.vue";
import HeroPlayer from "@/components/careers/HeroPlayer.vue";
import OfficeParallax from "@/components/careers/OfficeParallax.vue";
import PositionsList from "@/components/careers/PositionsList.vue";
import AccordionSet from "@/components/AccordionSet.vue";
import Accordion from "@/components/Accordion.vue";
import StickyBanner from "@/components/StickyBanner.vue";

import { inTheNews, employeeAvatars } from "@/data/careers";

export default {
    data: () => {
        return {
            inTheNews,
            employeeAvatars,
            employeeCount: 84,
            yearsOnTop: 7,
            supportedLanguages: 18,
            heroActive: false,
            heroTransitioning: false,
            isMobileDevice: false,
            maxMobileWidth: 600,
            mobileHeroActive: false,
        };
    },

    components: {
        TransitionHeight,
        TalentCarousel,
        TassieSlider,
        TassieSliderMobile,
        AvatarDisplay,
        HeroPlayer,
        HlsVideo,
        OfficeParallax,
        PositionsList,
        AccordionSet,
        Accordion,
        StickyBanner,
    },

    methods: {
        handleFaqUpdate(e) {
            this.$refs.faqAccordions.update(e);
        },

        handleHeroActive() {
            if (this.isMobileDevice && window.innerWidth < this.maxMobileWidth) {
                try {
                    this.$refs.mobileHeroVid.$el
                        .requestFullscreen()
                        .then(() => {
                            this.mobileHeroActive = true;
                            this.$refs.mobileHeroVid.$el.play();
                        })
                        .catch(() => {
                            this.mobileHeroActive = true;
                            this.$refs.mobileHeroVid.$el.play();
                        });
                } catch {
                    this.mobileHeroActive = true;
                    this.$refs.mobileHeroVid.$el.play();
                }
            } else {
                this.heroActive = true;
            }
        },

        goToPositions() {
            const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`);
            this.$refs.positions.scrollIntoView({
                behavior: isReduced.matches ? "instant" : "smooth",
            });
        },

        setupBannerObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: [0, 0.5],
            };
            const observer = new IntersectionObserver(([entry] = entries) => {
                if (!entry.intersectionRatio < 0.5) {
                    this.heroActive = false;
                }
            }, options);

            observer.observe(this.$refs.heroVidWrapper);
        },
    },

    computed: {
        ...mapGetters({
            positionCount: "employment/getPositionCount",
            positions: "employment/getPositions",
        }),

        flooredEmployeeCount() {
            return Math.floor(this.employeeCount / 10) * 10;
        },

        yearsSinceFounded() {
            return new Date().getFullYear() - 2011;
        },

        freedomTiles() {
            return [
                {
                    heading: this.$t("careers.independent.heading", { founded: this.yearsSinceFounded }),
                    body: this.$t("careers.independent.body"),
                    bg_type: "light",
                },
                {
                    heading: this.$t("careers.open_positions.heading", { open_positions: this.positionCount }),
                    buttonLabel: this.$t("careers.open_positions.button_label"),
                    bg_type: "dark",
                },
                {
                    heading: this.$t("careers.app_store.heading"),
                    body: this.$t("careers.app_store.body", { years: this.yearsOnTop }),
                    bg_type: "primary",
                },
                {
                    heading: this.$t("careers.millions.heading"),
                    body: this.$t("careers.millions.body", { languages: this.supportedLanguages }),
                    bg_type: "dark",
                },
            ];
        },
    },

    mounted() {
        this.isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");

        this.setupBannerObserver();

        document.addEventListener(
            "fullscreenchange",
            (e) => {
                if (document.fullscreenElement == null) {
                    this.mobileHeroActive = false;
                    this.$refs.mobileHeroVid.$el.pause();
                    this.$refs.mobileHeroVid.$el.currentTime = 0;
                }
            },
            false
        );

        document.addEventListener("keyup", (e) => {
            if (e.code == "Escape" && this.heroActive) {
                this.heroActive = false;
            }
        });
    },

    watch: {
        heroActive() {
            this.heroTransitioning = true;
            setTimeout(() => {
                this.heroTransitioning = false;
            }, 900);
        },
    },

    async asyncData({ store }) {
        try {
            await store.dispatch("employment/fetchPositions");
        } catch (e) {}
    },

    head() {
        const i18nHead = this.$nuxtI18nSeo();

        return {
            title: this.$t("careers.page_title"),
            meta: [
                ...metaTitles(this.$t("careers.meta.title")),
                ...metaDescriptions(this.$t("careers.meta.description")),
                ...metaImages(this.$asset("images/careers/metadata.jpg")),
                ...constructi18nHeadMeta(["en"], "en"),
            ],
            link: [...trimI18nHeadLinks(i18nHead.link, ["en"])],
            bodyAttrs: {
                class: "careers-page include-dynamic-container-nav",
            },
        };
    },
};
</script>
