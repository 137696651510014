<template>
    <div class="careers-hero-player">
        <div class="careers-hero-player__controls">
            <div class="careers-hero-player__control-group">
                <button @click="handleTogglePlay" type="button" ref="playButton" :aria-label="playToggleLabel" :aria-controls="blockId">
                    <span v-if="loading && !paused" class="careers-hero-player__spinner"></span>
                    <span v-else>
                        <ProIcon v-if="ended" icon="reset" />
                        <ProIcon v-if="!ended && !paused" icon="pause" />
                        <ProIcon v-if="!ended && paused" icon="play" />
                    </span>
                </button>
            </div>
            <div class="careers-hero-player__control-group">
                <button @click="handleToggleMute" type="button" :aria-label="muteToggleLabel" :aria-controls="blockId">
                    <ProIcon v-if="!muted" icon="sound-active" />
                    <ProIcon v-if="muted" icon="sound-muted" />
                </button>
            </div>
        </div>
        <div @click="handleTogglePlay">
            <HlsVideo
                :id="blockId"
                srcId="hVnr8VkixrUvpDWal2i1mIudQVKF6Wh02dNUcNdDLm7I"
                v-autoplay
                @success="paused = false"
                @failed="handleAutoplayFailure"
                ref="video"
                playsinline="playsinline"
                disableremoteplayback
                x-webkit-airplay="deny"
                contenteditable="false"
            />
        </div>

        <!-- <video
            src="https://s3.us-west-2.amazonaws.com/assets.procreate.art/careers/careers-video.mp4?v=1"
        ></video> -->
    </div>
</template>
<script>
import HlsVideo from "@/components/HlsVideo.vue";
import DynamicVideoSet from "@/components/DynamicVideoSet.vue";
import { uid } from "@/utils";

export default {
    components: { DynamicVideoSet, HlsVideo },

    data: () => {
        return {
            paused: true,
            muted: false,
            ended: false,
            captionsActive: false,
            loading: true,
            blockId: null,
        };
    },

    computed: {
        playToggleLabel() {
            if (this.ended) return this.$t("global.vid_controls.replay");
            if (!this.paused) return this.$t("global.vid_controls.pause");
            return this.$t("global.vid_controls.play");
        },

        muteToggleLabel() {
            if (!this.muted) return this.$t("global.vid_controls.mute");
            return this.$t("global.vid_controls.unmute");
        },
    },

    methods: {
        handleAutoplayFailure() {
            console.log("i failed!");
            this.paused = true;
            this.loading = false;
        },

        handleTogglePlay() {
            //Play the video
            if (this.ended || this.paused == true) {
                this.ended = false;
                this.$refs.video.$el.play().then(() => {
                    this.paused = false;
                });
            } //Pause the video
            else if (this.paused == false) {
                this.paused = true;
                this.$refs.video.pause();
            }
        },

        handleToggleMute() {
            //Mute video
            if (this.muted == false) {
                this.muted = true;
                this.$refs.video.$el.muted = true;
            }
            //unmute video
            else if (this.muted == true) {
                this.muted = false;
                this.$refs.video.$el.muted = false;
            }
        },
    },

    beforeMount() {
        this.blockId = uid();
    },

    mounted() {
        this.$refs.video.$el.addEventListener("ended", () => {
            this.ended = true;
        });
        this.$refs.video.$el.addEventListener("waiting", (e) => {
            this.loading = true;
        });
        this.$refs.video.$el.addEventListener("playing", (e) => {
            this.loading = false;
        });
    },
};
</script>
