<template>
    <div v-bind="$attrs" :class="`${expanded ? 'accordion--expanded ' : ''}${theme}`" class="accordion" role="group">
        <button
            type="button"
            class="text-heading text-heading--xs accordion__head"
            :aria-controls="blockId"
            :id="`${blockId}-trigger`"
            @click="handleOpen()"
            :aria-expanded="false"
        >
            <span v-html="heading"></span>
            <ProIcon :icon="iconCollapse" v-if="expanded" />
            <ProIcon :icon="iconExpand" v-else />
        </button>
        <div class="accordion__expand-wrapper" :id="blockId" :aria-labelledby="`${blockId}-trigger`">
            <transition-height>
                <div class="accordion__body" v-show="expanded == true">
                    <slot />
                </div>
            </transition-height>
        </div>
    </div>
</template>

<script>
import TransitionHeight from "@/components/TransitionHeight.vue";
import { uid } from "@/utils";

export default {
    components: { TransitionHeight },
    data: () => {
        return {
            blockId: null,
            expanded: false,
        };
    },
    props: {
        heading: {
            type: String,
            required: true,
        },
        theme: {
            default: "accordion--theme-default",
            type: String,
        },
        iconExpand: {
            default: "arrow-s",
            type: String,
        },
        iconCollapse: {
            default: "arrow-n",
            type: String,
        },
    },
    methods: {
        handleOpen() {
            this.expanded = !this.expanded;
            if (this.expanded) this.$emit("update", this.blockId);
        },
    },
    beforeMount() {
        this.blockId = uid();
    },
};
</script>
