import i18nConfig from "../i18n.config";

const isoFromLocaleCode = (code) => {
    return i18nConfig.locales.find((locale) => locale.code == code).iso;
};

export const trimI18nHeadLinks = (links, locales = []) => {
    const filteredHrefLangs = [
        ...i18nConfig.locales
            .filter((locale) => {
                return locales.includes(locale.code);
            })
            .map((locale) => {
                return locale.iso;
            })
            .flat(),
    ];

    let filteredLinks = links.filter((link) => {
        return filteredHrefLangs.includes(link.hreflang) || link.rel == "canonical" || link.hreflang == "x-default";
    });
    return filteredLinks;
};

export const constructi18nHeadMeta = (locales = [], preferredLocale) => {
    return locales.map((locale) => {
        const iso = isoFromLocaleCode(locale);
        //set preferred locale to this.$i18n.locale if there is no preferred
        if (locale == preferredLocale) {
            return {
                hid: "i18n-og",
                property: "og:locale",
                content: iso,
            };
        }
        return {
            hid: `i18n-og-alt-${iso}`,
            property: "og:locale:alternate",
            content: iso,
        };
    });
};

export const updateI18nHeadDefaultLink = (links, def) => {
    return links.map((link) => {
        if (link.hreflang == "x-default") {
            return {
                hid: "i18n-xd",
                rel: "alternate",
                hreflang: "x-default",
                href: links.find((link) => link.rel == "canonical").href, // there should always be a canonical link, and it should always be for the current page, so will always be correct.
            };
        }

        return link;
    });
};
