<template>
    <div v-bind="$attrs" class="cta-sticky" :class="`${active ? 'cta-sticky--active' : ''} ${theme}`" :intert="!active">
        <div class="cta-sticky__size-wrapper">
            <div class="cta-sticky__wrapper">
                <div :class="`${containerType}`">
                    <div class="cta-sticky__content">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            active: false,
        };
    },
    props: {
        triggerEl: {
            required: true,
        },
        containerType: {
            default: "container",
            type: String,
        },
        theme: {
            default: "cta-sticky--theme-default",
            type: String,
        },
    },

    methods: {
        setupStickyObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: [0, 1],
            };
            const observer = new IntersectionObserver(([entry] = entries) => {
                this.active = !entry.isIntersecting;
            }, options);

            observer.observe(this.triggerEl);
        },
    },

    mounted() {
        this.setupStickyObserver();
    },
};
</script>
