<template>
    <div
        v-if="slides.length > 0"
        ref="wrapper"
        aria-roledescription="carousel"
        class="tassie-slider-mobile tassie-slider-mobile"
        :aria-label="$t('careers.tassie.carousel_name')"
    >
        <div class="tassie-slider__nav sr-only">
            <div class="tassie-slider__indicator">
                <p aria-hidden="true">{{ currentSlide + 1 }} / {{ slides.length }}</p>
            </div>
            <button
                type="button"
                @click="updateCurrentSlide(currentSlide - 1)"
                :disabled="currentSlide == 0"
                :aria-controls="blockId"
                :aria-label="$t('global.carousel_prev')"
            >
                <ProIcon icon="arrow-w" />
            </button>
            <button
                type="button"
                @click="updateCurrentSlide(currentSlide + 1)"
                :disabled="currentSlide + 1 == slides.length"
                :aria-controls="blockId"
                :aria-label="$t('global.carousel_next')"
            >
                <ProIcon icon="arrow-e" />
            </button>
        </div>
        <div :id="blockId" :style="{ width: `${windowWidth}px` }" class="tassie-slider-mobile__wrapper">
            <div
                class="tassie-slider-mobile__track"
                :class="{ 'tassie-slider-mobile__track--dragging': dragInit }"
                :style="{
                    width: `${trackWidth}px`,
                    left: `-${currentSlide * slideWidth}px`,
                    transform: `translateX(${dragAmount + windowWidth * peekFactor ?? 0}px)`,
                }"
                @mousedown="handleDragInit"
                @mousemove="handleDragMove"
                @mouseup="handleDragEnd"
                @mouseleave="handleDragEnd"
                @touchstart="handleDragInit"
                @touchmove="handleDragMove"
                @touchend="handleDragEnd"
            >
                <div :style="{ width: `${slideWidth}px` }" v-for="(img, i) in slides" :key="i" aria-roledescription="slide" class="tassie-slider-mobile__slide">
                    <div class="tassie-slider-mobile__slide-wrapper">
                        <img :src="$asset(img.src)" :alt="img.alt" width="1512" height="1344" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TassieSlides } from "@/data/careers";
import { uid } from "@/utils";

export default {
    data: () => {
        return {
            slides: TassieSlides,
            currentSlide: 0,
            dragInit: null,
            dragAmount: 0,
            peekFactor: 0.1,
            windowWidth: 0,
            blockId: "",
        };
    },

    computed: {
        slideWidth() {
            const peek = this.windowWidth * this.peekFactor;
            return this.windowWidth - peek * 2;
        },
        trackWidth() {
            return this.slideWidth * this.slides.length;
        },
    },

    methods: {
        setWindowWidth() {
            this.windowWidth = document.body.clientWidth;
        },
        updateCurrentSlide(slide) {
            this.currentSlide = slide;
        },

        handleDragInit(event) {
            this.dragInit = event.clientX ?? event.touches[0].clientX;
        },

        handleDragMove(event) {
            if (this.dragInit) {
                if (event?.touches) {
                    this.handleBodyFreeze();
                }
                const touchTarget = event.clientX ?? event.touches[0].clientX;
                const amount = touchTarget - this.dragInit;
                const modifier = 0.8;
                this.dragAmount = amount * modifier;
            }
        },

        handleBodyFreeze() {
            if ((this.dragAmount > 0 && this.dragAmount > 40) || (this.dragAmount < 0 && this.dragAmount < -40)) {
                document.querySelector("html").classList.add("prevent-overflow");
            }
        },

        handleDragEnd() {
            document.querySelector("html").classList.remove("prevent-overflow");
            const threshold = 20;
            if (this.dragAmount < threshold * -1 && this.currentSlide + 1 != this.slides.length) {
                this.updateCurrentSlide(this.currentSlide + 1);
            } else if (this.dragAmount > threshold && this.currentSlide != 0) {
                this.updateCurrentSlide(this.currentSlide - 1);
            }
            this.dragInit = null;
            this.dragAmount = 0;
        },
    },

    mounted() {
        new ResizeObserver(() => {
            this.setWindowWidth();
        }).observe(this.$refs.wrapper);
        this.setWindowWidth();
    },

    beforeMount() {
        this.blockId = uid();
    },
};
</script>
