<template>
    <div v-bind="$attrs" role="list" class="accordion-set">
        <slot />
    </div>
</template>
<script>
import Accordion from "@/components/Accordion.vue";
export default {
    props: {
        collapses: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Accordion,
    },
    methods: {
        update(e) {
            if (!this.collapses) return;
            const close = this.$slots.default.filter((b) => {
                return b?.componentInstance?.blockId && b.componentInstance.blockId != e;
            });
            close.forEach((b) => {
                b.componentInstance.expanded = false;
            });
        },
    },
};
</script>
