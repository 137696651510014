<template>
    <div class="avatar-display" aria-hidden="true">
        <div class="avatar-display__groups">
            <div class="avatar-display__group" v-for="i in displayFactor">
                <span :class="{ active: activeSet == i }" class="avatar-display__avatar" v-for="(avatar, i) in avatarGroups[i - 1]">
                    <img :src="$asset(avatar)" alt="" loading="lazy" />
                </span>
            </div>
        </div>
        <div class="avatar-display__avatar text-body avatar-display__count">+{{ total }}</div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            displayFactor: 3,
            avatarGroups: [],
            activeSet: 0,
            changeInterval: null,
        };
    },

    props: {
        avatars: Array,
        total: Number,
    },

    beforeMount() {
        const leftover = this.avatars.length % this.displayFactor;
        const cleanArray = [...this.avatars].slice(0, this.avatars.length - leftover);
        for (let i = 0; i < cleanArray.length; i = i + cleanArray.length / this.displayFactor) {
            this.avatarGroups.push(cleanArray.slice(i, i + cleanArray.length / this.displayFactor));
        }
    },

    mounted() {
        this.changeInterval = setInterval(() => {
            const maxSet = this.avatarGroups[1].length - 1;
            if (this.activeSet >= maxSet) {
                this.activeSet = 0;
            } else {
                this.activeSet = this.activeSet + 1;
            }
        }, 6000);
    },

    beforeDestroy() {
        if (this.changeInterval) {
            clearTimeout(this.changeInterval);
        }
    },
};
</script>
