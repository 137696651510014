<template>
    <div>
        <div class="talent-carousel">
            <ul :aria-label="$t('careers.talent.list_label')" class="talent-carousel__content">
                <li v-for="(employee, i) in talentList" :key="i" class="talent-carousel__item">
                    <span class="sr-only">{{ employee.flag }} - {{ employee.name }} - {{ employee.position }}</span>
                    <div class="talent-carousel-item-avatar">
                        <img :src="$asset(employee.avatar)" alt="" loading="lazy" />
                    </div>
                    <div class="talent-carousel-item-copy" aria-hidden="true">
                        <p class="talent-carousel-item-nation">{{ employee.flag }}</p>
                        <p class="talent-carousel-item-name text-heading text-heading--xxs">{{ employee.name }}</p>
                        <p class="talent-carousel-item-position text-body text-body--xs">{{ employee.position }}</p>
                    </div>
                </li>
            </ul>
            <ul role="presentation" class="talent-carousel__content" aria-hidden="true">
                <li v-for="(employee, i) in talentList" :key="i" class="talent-carousel__item">
                    <div class="talent-carousel-item-avatar">
                        <img :src="$asset(employee.avatar)" alt="" loading="lazy" />
                    </div>
                    <div class="talent-carousel-item-copy">
                        <p class="talent-carousel-item-nation">{{ employee.flag }}</p>
                        <p class="talent-carousel-item-name text-heading text-heading--xxs">{{ employee.name }}</p>
                        <p class="talent-carousel-item-position text-body text-body--xs">{{ employee.position }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="talent-carousel talent-carousel--mobile" aria-hidden="true">
            <ul role="presentation" class="talent-carousel__content">
                <li v-for="(employee, i) in talentList" :key="i" class="talent-carousel__item">
                    <div class="talent-carousel-item-avatar">
                        <img :src="$asset(employee.avatar)" alt="" loading="lazy" />
                    </div>
                    <div class="talent-carousel-item-copy">
                        <p class="talent-carousel-item-nation">{{ employee.flag }}</p>
                        <p class="talent-carousel-item-name text-heading text-heading--xxs">{{ employee.name }}</p>
                        <p class="talent-carousel-item-position text-body text-body--xs">{{ employee.position }}</p>
                    </div>
                </li>
            </ul>
            <ul role="presentation" class="talent-carousel__content">
                <li v-for="(employee, i) in talentList" :key="i" class="talent-carousel__item">
                    <div class="talent-carousel-item-avatar">
                        <img :src="$asset(employee.avatar)" alt="" loading="lazy" />
                    </div>
                    <div class="talent-carousel-item-copy">
                        <p class="talent-carousel-item-nation">{{ employee.flag }}</p>
                        <p class="talent-carousel-item-name text-heading text-heading--xxs">{{ employee.name }}</p>
                        <p class="talent-carousel-item-position text-body text-body--xs">{{ employee.position }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { talentList } from "@/data/careers.js";
export default {
    data: () => {
        return {
            talentList,
        };
    },
};
</script>
